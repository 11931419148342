///* eslint-disable */
import { load } from 'recaptcha-v3';
import { email, leadAssign } from './api';
import { __ } from './Txlat';

const siteKey = '6LdDaJUhAAAAAIVVGZHI0YmKTOt-ia-XKVFRmM5M';

async function getCaptcha() {
  //console.log('in getCaptcha............');
  const recaptcha = await load(siteKey);
  //console.log(recaptcha);
  return recaptcha;
}

async function getCaptchaToken(captcha) {
  //console.log('in getCaptchaToken............');
  const token = await captcha.execute('validate_captcha');
  //console.log('token:', token);
  return token;
}

function form2Msg(form) {
  //console.log('In form2Msg.......');
  const msgFields = form.querySelectorAll('[data-msgfield]');
  //console.log(msgFields);

  let retVal = __('A new message has been received') + ':\r\n\r\n';
  msgFields.forEach((element) => {
    if ((element.type === 'radio') && (!element.checked)) { return; }
    const fieldName = element.dataset.msgfield.replace('\\r\\n', '\r\n');
    const fieldValue = element.value;
    //console.log(fieldName + fieldValue + '\r\n');
    retVal += (fieldName + fieldValue + '\r\n');
  });

  retVal += '\r\n';

  return retVal;
}

async function sendEmail(gcaptcha, msgBody, msgType, msgSub, to) {

  //console.log('in formSubmit............');
  //console.log('captcha:', gcaptcha);

  const token = await captcha.token(gcaptcha);
  //console.log('token:', token);

  const response = await email.post(token, msgType, msgSub, msgBody, to);
  //console.log(response);

  return response.body;
}

async function sendLeadAssign(gcaptcha, data) {
  const token = await captcha.token(gcaptcha);
  const requestData = {
    ...data,
    recaptchaToken: token
  };
  const response = await leadAssign.post(requestData);
  return response.body;
}

export const captcha = {
  get: () => getCaptcha(),
  token: c => getCaptchaToken(c),
  email: (c, form, msgType, msgSub, to) => sendEmail(c, form2Msg(form), msgType, msgSub, to),
  emailAgent: (c, msgBody, msgType, msgSub, to) => sendEmail(c, msgBody, msgType, msgSub, to),
  leadAssign: (c, data) => sendLeadAssign(c, data),
};
